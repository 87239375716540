import React from 'react';
import { Link } from 'react-router-dom';

function InnerPagesHeader() {
    const styles = {
        header:{
          position: 'relative',
          top: 0,
          left: 0,
          right: 0,
          padding: '1rem',
          zIndex: 1000,
          maxWidth: '1200px',
          width: '100%',
          backgroundColor: '#ffffff',
          height: '5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        },
        title:{
            color: 'rgb(26,26,26)',
            fontSize: '2rem',
            fontWeight: '600',
            fontFamily: "'Poppins', Arial, sans-serif",
            letterSpacing: '0.5px',
            textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
            position:'absolute',
            left: '15px'
        },
        homeLink:{
            color: 'rgb(26, 26, 26)',
            textDecoration: 'none',
            position:'absolute',
            right: '15px',
            cursor: 'pointer'
        }

    }

    return (

      <div style={styles.header}>
         <div style={styles.title}>Subtitles.center</div>
         <Link to="/" style={styles.homeLink}>Home</Link>

      </div>
    )

}

export default InnerPagesHeader;
