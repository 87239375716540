import React, { useState, useEffect} from 'react';
import heroBackground from './assets/hero-background.jpg';
import { Link, useLocation } from 'react-router-dom';

function Footer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [expandedSections, setExpandedSections] = useState({});
  const [showpath, setShowpath] = useState();

  let currentLocation = useLocation();

  useEffect(() => { 
    if(currentLocation.pathname === '/'){
      setShowpath('./')
    }
    else{
      setShowpath('../')
    }
  }, [currentLocation]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);


  }, []);

  const colors = {
    primaryBlue: '#3498db',
    darkGrey: '#34495e',
    white: '#ffffff',
  };
  const styles = {
    section: {
      padding: '4rem 2rem',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    sectionWrapper: {
      width: '100%',
      backgroundImage: `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.4)), url(${heroBackground})`,
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    footer: {
      backgroundColor: colors.darkGrey,
      color: colors.white,
      padding: '2rem',
    },
    footerContent: {
      maxWidth: '800px',
      margin: '0 auto',
    },
    footerLinks: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    linkGroup: {
      marginBottom: '1rem',
      width: isMobile ? '100%' : 'auto',
    },
    linkGroupTitle: {
      fontSize: '0.95rem',
      fontWeight: 'bold',
      marginBottom: '0.5rem',
      cursor: isMobile ? 'pointer' : 'default',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    linkList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: isMobile ? 'none' : 'block',
      maxHeight: isMobile ? '0' : 'none',
      overflow: 'hidden',
      transition: 'max-height 0.3s ease-out',
      fontSize: '0.85rem'
    },
    linkListExpanded: {
      display: 'block',
      maxHeight: '500px', // Adjust this value as needed
    },
    linkItem: {
      marginBottom: '0.5rem',
    },
    link: {
      color: colors.white,
      textDecoration: 'none',
    },
    copyright: {
      textAlign: 'center',
      marginTop: '2rem',
      fontSize: '0.8rem',
    },
  };

  const footerLinks = {
    Context: [
      { name: 'International laws', url: showpath+'subtitles-international-laws' },
    ],
    'Earn As': [
      { name: 'Affiliate', url: showpath+'earn/subtitles-center-affiliate' },
      { name: 'Subtitle Editor', url: showpath+'earn/subtitles-center-earn-by-editing-subtitles' },
      { name: 'Franchise', url: showpath+'earn/subtitles-center-franchise' },
    ],
    Company: [
      { name: 'About', url: showpath+'about-subtitles-center' },
      { name: 'Safety', url: showpath+'subtitles-center-privacy-safety' },
      { name: 'Careers', url: showpath+'subtitles-center-careers' },
    ],
  };

  const toggleSection = (section) => {
    if (isMobile) {
      setExpandedSections(prev => ({
        ...prev,
        [section]: !prev[section]
      }));
    }
  };
  



  return (

    <><div style={styles.sectionWrapper}>
      <section id="cta" style={styles.section}>
        <div style={{ textAlign: 'center', maxWidth: '800px', margin: '0 auto' }}>
          <h2 style={{ fontSize: '2rem', color: 'rgb(52, 73, 94)', marginBottom: '0.5rem' }}>
            From Silent to Global
          </h2>
          <h2 style={{ fontSize: '2rem', color: 'rgb(52, 73, 94)', marginBottom: '2.5rem' }}>
            Empower Your Videos with AI Subtitles
          </h2>

          <a
            href="https://my.subtitles.center/register"
            style={{
              display: 'inline-block',
              padding: '12px 24px',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              color: colors.white,
              backgroundColor: 'rgb(52, 73, 94)',
              borderRadius: '5px',
              textDecoration: 'none',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = colors.darkBlue}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'rgb(52, 73, 94)'}
          >
            Try for Free
          </a>
        </div>
      </section>
    </div>
    <footer style={styles.footer}>
      <div style={styles.footerContent}>
        <div style={styles.footerLinks}>
          {Object.entries(footerLinks).map(([group, links]) => (
            <div key={group} style={styles.linkGroup}>
              <h3 
                style={styles.linkGroupTitle} 
                onClick={() => toggleSection(group)}
              >
                {group}
                {isMobile && (
                  <span style={{display: 'inline-block', marginLeft: '0.5rem'}}>
                    {expandedSections[group] ? '▲' : '▼'}
                  </span>
                )}
              </h3>
              <ul style={{
                ...styles.linkList,
                ...(isMobile && expandedSections[group] ? styles.linkListExpanded : {}),
              }}>
                {links.map((link) => (
                  <li key={link.name} style={styles.linkItem}>
                    <Link to={link.url} style={styles.link}>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <p style={styles.copyright}>
          &copy; {new Date().getFullYear()} Subtitles.center
        </p>
      </div>
    </footer>
</>
);
}

export default Footer;