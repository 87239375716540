import React, {useEffect} from 'react';
import Footer from './Footer'
import InnerPagesHeader from './InnerPagesHeader'
import { Helmet } from "react-helmet";

function Careers() {

  const colors = {
    primaryBlue: '#3498db',
    darkGrey: '#34495e',
    white: '#ffffff',
  };

  const styles = {
    page: {
      fontFamily: 'Arial, sans-serif',
      color: colors.darkGrey,
      margin: 0,
      padding: 0,
      backgroundColor: colors.white,
    },
    mainTitle: {
      color: 'rgb(26,26,26)',
      padding: '2rem',
      textAlign: 'center',
    },
    title: {
      fontSize: '2.5rem',
      marginBottom: '1rem',
    },
    content: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '2rem',
    },
    section: {
      marginBottom: '2rem',
      minHeight: '100px'
    },
    sectionTitle: {
      fontSize: '1.8rem',
      color:  'rgb(26,26,26)',
      marginBottom: '1rem',
    },
    paragraph: {
      lineHeight: '1.6',
      marginBottom: '1rem',
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  
  return (
    <><Helmet>
      <title>Video subtitles career - Subtitles.center</title>
    </Helmet>
    <div style={styles.page}>
        <InnerPagesHeader />
        <main style={styles.content}>
          <div style={styles.mainTitle}>
            <h1 style={styles.title}>Coming Soon</h1>
          </div>
          <section style={styles.section}>&nbsp;</section>
        </main>
        <Footer />
      </div></>
  );
}

export default Careers;
