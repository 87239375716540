import React, { useState, useEffect, useRef, useMemo } from 'react';
import heroBackground from './assets/hero-background.jpg';
import contentCreator from './assets/content-creator.png';
import corporate from './assets/corporate.jpg';
import learning from './assets/learning.jpg';
import videoProduction from './assets/video-production.jpg';
import testimonialsMichael from './assets/Michael.jpg';
import testimonialsSarah from './assets/Sarah.jpg';
import testimonialsEmma from './assets/Emma.jpg';
import testimonialsNaomi from './assets/Naomi.jpg';
import { languages } from './languages';
import Footer from './Footer'

function Home() {


const colors = {
    primaryBlue: '#3498db',
    lightBlue: '#ebf5fb',
    darkGrey: '#34495e',
    mediumGrey: '#95a5a6',
    lightGrey: '#ecf0f1',
    white: '#ffffff',
    almostBlack: '#1a1a1a',
  
  };
  
const styles = {
    app: {
      fontFamily: 'Arial, sans-serif',
      color: colors.darkGrey,
      margin: 0,
      padding: 0,
      backgroundColor: colors.white,
    },
    header: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      padding: '1rem',
      zIndex: 1000,
    },
    nav: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    logo: {
      color: colors.almostBlack,
      fontSize: '2.5rem',
      fontWeight: '600',
      fontFamily: "'Poppins', Arial, sans-serif",
      letterSpacing: '0.5px',
      textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
    },
    menuIcon: {
      color: colors.white,
      fontSize: '1.5rem',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    menuItems: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      justifyContent: 'center',
    },
    link: {
      color: colors.almostBlack,
      textDecoration: 'none',
    },
    mobileMenu: {
      position: 'fixed',
      top: '4rem',
      left: 0,
      right: 0,
      backgroundColor: 'rgb(187, 215, 234)',
      padding: '1rem',
      zIndex: 999,
    },
    mobileMenuItem: {
      display: 'block',
      padding: '0.5rem 0',
      color: colors.almostBlack,
      textDecoration: 'none',
    },
    section: {
      padding: '4rem 2rem',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    sectionWrapper: {
      width: '100%',
      backgroundImage: `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.4)), url(${heroBackground})`,
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    hero: {
      backgroundImage: `linear-gradient(rgba(52, 152, 219, 0.2), rgba(52, 152, 219, 0.2)), url(${heroBackground})`,
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.almostBlack,
      textAlign: 'center',
    },
    heroContent: {
      maxWidth: '800px',
      padding: '2rem',
      borderRadius: '10px',
    },
    heroTitle: {
      fontSize: '4rem',
      marginBottom: '1rem',
    },
    heroSubtitle: {
      fontSize: '2rem',
      marginBottom: '2rem',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '2rem',
    },
    card: {
      backgroundColor: colors.white,
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(52, 152, 219, 0.1)',
    },
    cardPricing: {
      backgroundColor: 'rgb(187, 215, 234)',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(52, 152, 219, 0.1)',
      paddingLeft: '50px'
    },
    cardPricingButton: {
      backgroundColor: colors.white,
      color: colors.primaryBlue,
      border: 'none',
      padding: '0.5rem 2rem',
      fontSize: '1.2rem',
      borderRadius: '5px',
      cursor: 'pointer',
      fontWeight: 'bold',
      position: 'relative',
      left: '40%',
      transform: 'translateX(-50%)',
      marginBottom: '10px'  
    },
    button: {
      backgroundColor: colors.white,
      color: colors.primaryBlue,
      border: 'none',
      padding: '1rem 2rem',
      fontSize: '1.2rem',
      borderRadius: '5px',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
    useCaseImage: {
      width: '100%',
      height: '200px',
      objectFit: 'cover',
      borderRadius: '10px 10px 0 0',
    },
    useCaseContent: {
      padding: '1.5rem',
    },
    testimonialsContent:{
      padding: '1rem',
    },
    contactForm: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      maxWidth: '500px',
      margin: '0 auto',
    },
    input: {
      padding: '0.5rem',
      fontSize: '1rem',
      borderRadius: '5px',
      border: `1px solid ${colors.mediumGrey}`,
      backgroundColor: 'rgb(228 236 242)',
    },
    textarea: {
      padding: '0.5rem',
      fontSize: '1rem',
      borderRadius: '5px',
      border: `1px solid ${colors.mediumGrey}`,
      backgroundColor: 'rgb(228 236 242)',
      minHeight: '150px',
      fontFamily: 'Arial, sans-serif'
    },
    languageCloud: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '300px',
    },
    languageItem: {
      margin: '5px',
      padding: '5px 10px',
      borderRadius: '15px',
      fontSize: '0.9rem',
      color: colors.darkGrey,
      transition: 'all 0.3s ease',
    },
    loginButton: {
      backgroundColor: colors.white,
      color: 'rgb(26,26,26)',
      padding: '8px 16px',
      borderRadius: '5px',
      fontWeight: 'bold',
      textDecoration: 'none',
      transition: 'background-color 0.3s ease, color 0.3s ease',
    },  
    enterButton: {
      backgroundColor: colors.white,
      color: 'rgb(26,26,26)',
      padding: '12px 36px',
      borderRadius: '5px',
      fontWeight: 'bold',
      textDecoration: 'none',
      transition: 'background-color 0.3s ease, color 0.3s ease',
      fontSize: '1.5rem'
    },
    sendMsgBtn: {
      backgroundColor: 'rgb(255, 255, 255)',
      color: 'rgb(52, 152, 219)',
      padding: '1rem 2rem',
      fontSize: '1.2rem',
      borderRadius: '5px',
      cursor: 'pointer',
      fontWeight: 'bold',
      border: '1px solid rgb(187, 215, 234)',   
    }  
  };



  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isEnterBtnHovered, setIsEnterBtnHovered] = useState(false);
  const contactFormRef = useRef(null);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    document.body.style.margin = '0';
    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.style.margin = '';
    };
  }, []);


  const shuffledLanguagesWithStyles = useMemo(() => {
    const softBlues = ['#b3d9ff', '#99ccff', '#80bfff'];
    return [...languages]
      .sort(() => 0.5 - Math.random())
      .map(lang => ({
        ...lang,
        fontSize: `${0.8 + Math.random() * 0.4}rem`,
        opacity: 0.7 + Math.random() * 0.3,
        backgroundColor: softBlues[Math.floor(Math.random() * softBlues.length)],
      }));
  }, []);

  const notificationStyles = {
    position: 'fixed',
    top: '20px',
    right: '20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '15px',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    zIndex: 1000,
    transition: 'opacity 0.3s ease-in-out',
    opacity: isNotificationVisible ? 1 : 0,
    pointerEvents: isNotificationVisible ? 'auto' : 'none',
  };


  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };
  const scrollToContact = () => {
    contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const openSubtitlesCenter = () => {
    window.open('https://my.subtitles.center/register');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };


  const submitContactForm = (e) => {
    e.preventDefault();
    const url = '../contact-form.php'
       const requestMetadata = {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json',
               'Authorization': ''
           },
           body: JSON.stringify(formData)
       };
 
       fetch(url, requestMetadata)
       .then(async response => {
          const data = await response.json();
          //console.log('data '+JSON.stringify(data))
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response statusText
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
          }
          else{
              if(data === 1){
                console.log('sent') 
                setIsNotificationVisible(true);
                // Hide notification after 5 seconds
                setTimeout(() => setIsNotificationVisible(false), 5000);
                // Clear form data
                setFormData({ name: '', email: '', message: '' });
                        
              }           
    
          }
      })
      .catch(error => {
          //this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
      });
      //setFormData({ name: '', email: '', message: '' });
 }

  return (
    <div style={styles.app}>
      <header style={styles.header}>
        <nav style={styles.nav}>
          <div style={styles.logo}>Subtitles.center</div>
          {isMobile ? (
            <button style={styles.menuIcon} onClick={toggleMobileMenu}>☰</button>
          ) : (
            <div style={styles.menuItems}>
              <a 
                href="https://my.subtitles.center" 
                style={styles.loginButton}
              >
                Log in
              </a>
              <a href="#use-cases" style={styles.link}>Use Cases</a>
              <a href="#testimonials" style={styles.link}>Testimonials</a>
              <a href="#pricing" style={styles.link}>Pricing</a>
              <a href="#faq" style={styles.link}>FAQ</a>
              <a href="#contact" style={styles.link}>Contact</a>
            </div>
          )}
        </nav>
      </header>

      {isMobile && isMobileMenuOpen && (
        <div style={styles.mobileMenu}>
              <a 
                href="https://my.subtitles.center" 
                style={styles.mobileMenuItem}
                onClick={closeMobileMenu}
              >
                Log in
              </a>
          <a href="#use-cases" style={styles.mobileMenuItem} onClick={closeMobileMenu}>Use Cases</a>
          <a href="#testimonials" style={styles.mobileMenuItem} onClick={closeMobileMenu}>Testimonials</a>
          <a href="#pricing" style={styles.mobileMenuItem} onClick={closeMobileMenu}>Pricing</a>
          <a href="#faq" style={styles.mobileMenuItem} onClick={closeMobileMenu}>FAQ</a>
          <a href="#contact" style={styles.mobileMenuItem} onClick={closeMobileMenu}>Contact</a>
        </div>
      )}

      <main>
        <section style={styles.hero}>
          <div style={styles.heroContent}>
            <h1 style={styles.heroTitle}>AI-Powered Video Subtitles Editor</h1>
            <p style={styles.heroSubtitle}>Create accurate subtitles in minutes, not hours</p>
            <a 
                href="https://my.subtitles.center/register" 
                style={{
                  ...styles.enterButton,
                  backgroundColor: isEnterBtnHovered ? colors.lightGrey : colors.white,
                }}
                onMouseEnter={() => setIsEnterBtnHovered(true)}
                onMouseLeave={() => setIsEnterBtnHovered(false)}
                >
                Try for free
            </a>

          </div>
        </section>

        <section id="use-cases" style={styles.section}>
          <h2>Use Cases</h2>
          <div style={styles.grid}>
            <div style={styles.card}>
              <img 
                src={contentCreator} 
                alt="Content Creator" 
                style={styles.useCaseImage}
              />
              <div style={styles.useCaseContent}>
                <h3>Content Creators</h3>
                <p>Easily add subtitles to your YouTube videos and vlogs.</p>
              </div>
            </div>
            <div style={styles.card}>
              <img 
                src={learning} 
                alt="E-learning Platform" 
                style={styles.useCaseImage}
              />
              <div style={styles.useCaseContent}>
                <h3>E-learning Platforms</h3>
                <p>Make your online courses accessible to a global audience.</p>
              </div>
            </div>
            <div style={styles.card}>
              <img 
                src={videoProduction} 
                alt="Film Production" 
                style={styles.useCaseImage}
              />
              <div style={styles.useCaseContent}>
                <h3>Film Production</h3>
                <p>Streamline the subtitling process for movies and TV shows.</p>
              </div>
            </div>
            <div style={styles.card}>
              <img 
                src={corporate} 
                alt="Corporate Communications" 
                style={styles.useCaseImage}
              />
              <div style={styles.useCaseContent}>
                <h3>Corporate Communications</h3>
                <p>Enhance internal videos with multi-language subtitles.</p>
              </div>
            </div>
          </div>
        </section>
    
      <div style={styles.sectionWrapper}>
        <section id="testimonials" style={styles.section}>
          <h2>What Our Customers Say</h2>
          <div style={styles.grid}>
            <div style={styles.card}>
              <img src={testimonialsSarah} alt="Subtitles.center Customer Sarah" style={{width: '100px', borderRadius: '50%'}} />
              <div style={styles.testimonialsContent}>
                <p>"Subtitles.center has revolutionized our content production!"</p>
                <p><strong>Sarah J., Content Manager</strong></p>
              </div>
            </div>
            <div style={styles.card}>
              <img src={testimonialsMichael} alt="Subtitles.center Customer Michael" style={{width: '100px', borderRadius: '50%'}} />
              <div style={styles.testimonialsContent}>
                <p>"The accuracy of the AI transcription is impressive."</p>
                <p><strong>Michael L., Filmmaker</strong></p>
              </div>
            </div>
            <div style={styles.card}>
              <img src={testimonialsEmma} alt="Subtitles.center Customer Emma" style={{width: '100px', borderRadius: '50%'}} />
              <div style={styles.testimonialsContent}>
                <p>"As a YouTuber, this tool is now indispensable in my workflow."</p>
                <p><strong>Emma R., YouTuber</strong></p>
              </div>
            </div>
            <div style={styles.card}>
              <img src={testimonialsNaomi} alt="Subtitles.center Customer Testimonial" style={{width: '100px', borderRadius: '50%'}} />
              <div style={styles.testimonialsContent}>
                <p>"Subtitles.center helped me ace my language courses. The accurate transcriptions and translations are a game-changer for international students like me!"</p>
                <p><strong>Naomi T., University Student</strong></p>
              </div>
          </div>
             
          </div>
        </section>
      </div>

        <section id="pricing" style={styles.section}>
          <h2>Pricing</h2>
          <div style={styles.grid}>
            <div style={styles.cardPricing}>
              <h3>Basic</h3>
              <p>Free</p>
              <ul>
                <li>Unlimited hours of video</li>
                <li>AI transcription</li>
                <li>AI translation</li>
                <li>Basic editing tools</li>
                <li>Limited cloud space</li>
                <li>Regular queue</li>
              </ul>
              <button style={styles.cardPricingButton} onClick={openSubtitlesCenter}>Sign up</button>
            </div>
            
            <div style={styles.cardPricing}>
              <h3>Pro</h3>
              <p>$6.99/month</p>
              <ul>
                <li>Unlimited hours of video</li>
                <li>AI transcription</li>
                <li>AI translation</li>
                <li>Advanced editing tools</li>
                <li>Unlimited cloud space</li>
                <li>Priority queue</li>
              </ul>
              <button style={styles.cardPricingButton} onClick={openSubtitlesCenter}>Sign up</button>
            </div>
            
            <div style={styles.cardPricing}>
              <h3>Enterprise</h3>
              <p>Custom pricing</p>
              <ul>
                <li>Unlimited video hours</li>
                <li>AI transcription</li>
                <li>Translation support</li>
                <li>Super advanced editing tools</li>
                <li>Unlimited cloud space</li>
                <li>Dedicated account manager</li>
              </ul>
              <button style={styles.cardPricingButton} onClick={scrollToContact}>Contact Sales</button>
            </div>
          </div>
        </section>

        <section id="faq" style={styles.section}>
          <h2>Frequently Asked Questions</h2>
          <div style={styles.grid}>
            <div style={styles.card}>
              <h3>How accurate is the AI transcription?</h3>
              <p>Our AI transcription boasts an accuracy rate of over 95% for clear audio in most languages.</p>
            </div>
            <div style={styles.card}>
              <h3>What file formats are supported?</h3>
              <p>We support a wide range of video formats including MP4, AVI, MOV, and WMV.</p>
            </div>
            <div style={styles.card}>
              <h3>How long does it take to generate subtitles?</h3>
              <p>The AI can generate subtitles in about 1/4 of the video's duration.</p>
            </div>
            <div style={styles.card}>
              <h3>Can I edit the AI-generated subtitles?</h3>
              <p>Absolutely! Our intuitive editor allows you to easily refine the AI-generated subtitles.</p>
            </div>
          </div>
        </section>
      <div style={styles.sectionWrapper}>
        <section id="languages" style={styles.section}>
          <h2>What languages do you recognize?</h2>
          <div style={styles.languageCloud}>
            {shuffledLanguagesWithStyles.map((lang) => (
              <span
                key={lang.key}
                style={{
                  ...styles.languageItem,
                  fontSize: lang.fontSize,
                  opacity: lang.opacity,
                  backgroundColor: lang.backgroundColor,
                }}
              >
                {lang.name}
              </span>
            ))}          
          </div>
        </section>
      </div>  

        <section id="contact" style={styles.section} ref={contactFormRef}>
          <form style={styles.contactForm} onSubmit={submitContactForm}>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              style={styles.input}
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              style={styles.input}
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your Message"
              style={styles.textarea}
              value={formData.message}
              onChange={handleInputChange}
              required
            ></textarea>
            <button type="submit" style={styles.sendMsgBtn}>Send Message</button>
          </form>
        </section>
        <div style={notificationStyles}>
        Message sent successfully!
        </div>

      </main>

      <Footer/>
    </div>
  );


}  

export default Home;
