import React, {useEffect} from 'react';
import Footer from '../Footer'
import InnerPagesHeader from '../InnerPagesHeader'
import { Helmet } from "react-helmet";

function Franchise() {
    const colors = {
        primaryBlue: '#3498db',
        darkGrey: '#34495e',
        white: '#ffffff',
      };
    
      const styles = {
        page: {
          fontFamily: 'Arial, sans-serif',
          color: colors.darkGrey,
          margin: 0,
          padding: 0,
          backgroundColor: colors.white,
        },
        mainTitle: {
          color: 'rgb(26,26,26)',
          padding: '2rem',
          textAlign: 'center',
        },
        title: {
          fontSize: '2.5rem',
          marginBottom: '1rem',
        },
        content: {
          maxWidth: '800px',
          margin: '0 auto',
          padding: '2rem',
        },
        section: {
          marginBottom: '2rem',
        },
        sectionTitle: {
          fontSize: '1.8rem',
          color:  'rgb(26,26,26)',
          marginBottom: '1rem',
        },
        paragraph: {
          lineHeight: '1.6',
          marginBottom: '1rem',
        },
      };
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
  return (
    <><Helmet>
      <title>Subtitles.center franchise, earn on video subtitles</title>
    </Helmet>
    <div style={styles.page}>
        <InnerPagesHeader />
        <main style={styles.content}>
          <div style={styles.mainTitle}>
            <h1 style={styles.title}>Franchise Opportunity</h1>
          </div>

          <section style={styles.section}>
            <h2>Unlock the Potential of the Video Subtitles Industry</h2>
            <p>
              In today's globalized digital landscape, the demand for high-quality video subtitles has never been higher. By becoming a Subtitles.center franchise owner, you're tapping into a rapidly growing market with immense potential for profitability and growth.
            </p>
          </section>

          <section style={styles.section}>
            <h2>Benefits of Our Franchise Program</h2>
            <ul style={styles.list}>
              <li>Proven business model with a track record of success</li>
              <li>Access to our proprietary AI-powered subtitle generation technology</li>
              <li>Comprehensive training and ongoing support</li>
              <li>Exclusive territory rights</li>
              <li>Marketing and branding support</li>
              <li>Diverse revenue streams from various subtitle services</li>
            </ul>
          </section>

          <section style={styles.section}>
            <h2>The Profitable World of Video Subtitles</h2>
            <p>
              The video subtitles industry is experiencing unprecedented growth, driven by:
            </p>
            <ul style={styles.list}>
              <li>Explosion of streaming platforms and online content</li>
              <li>Increasing globalization of media</li>
              <li>Growing awareness of accessibility needs</li>
              <li>Rise of e-learning and educational content</li>
            </ul>
            <p>
              As a Subtitles.center franchise owner, you'll be positioned to capitalize on these trends and build a thriving, sustainable business.
            </p>
          </section>

          <section style={styles.section}>
            <h2>Why Choose Subtitles.center?</h2>
            <p>
              Our innovative AI technology sets us apart from traditional subtitle services, allowing for:
            </p>
            <ul style={styles.list}>
              <li>Faster turnaround times</li>
              <li>Higher accuracy rates</li>
              <li>Competitive pricing</li>
              <li>Scalability to handle projects of any size</li>
            </ul>
            <p>
              This technological edge translates into higher profit margins and a competitive advantage in the market.
            </p>
          </section>

          <section style={styles.section}>
            <h2>Ready to Join the Subtitles.center Family?</h2>
            <p>
              Becoming a Subtitles.center franchise owner is your opportunity to enter a lucrative industry with the support of a leading brand. Our team is ready to guide you through every step of the process, from initial inquiry to grand opening and beyond.
            </p>
            <p>
              To learn more about this exciting opportunity and start your journey towards owning a profitable Subtitles.center franchise, we invite you to get in touch with us.
            </p>
            <p>
              Use the contact form on our home page to express your interest, and one of our franchise specialists will reach out to provide you with detailed information and answer any questions you may have.
            </p>
            <p>
              Don't miss this chance to be part of the future of video subtitling. Contact us today and take the first step towards building your own successful Subtitles.center franchise!
            </p>
          </section>
        </main>
        <Footer />

      </div></>
  );
}

export default Franchise;