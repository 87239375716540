import React, {useEffect} from 'react';
import Footer from './Footer'
import InnerPagesHeader from './InnerPagesHeader'
import { Helmet } from "react-helmet";

function About() {

  const colors = {
    primaryBlue: '#3498db',
    darkGrey: '#34495e',
    white: '#ffffff',
  };

  const styles = {
    page: {
      fontFamily: 'Arial, sans-serif',
      color: colors.darkGrey,
      margin: 0,
      padding: 0,
      backgroundColor: colors.white,
    },
    mainTitle: {
      color: 'rgb(26,26,26)',
      padding: '2rem',
      textAlign: 'center',
    },
    title: {
      fontSize: '2.5rem',
      marginBottom: '1rem',
    },
    content: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '2rem',
    },
    section: {
      marginBottom: '2rem',
    },
    sectionTitle: {
      fontSize: '1.8rem',
      color:  'rgb(26,26,26)',
      marginBottom: '1rem',
    },
    paragraph: {
      lineHeight: '1.6',
      marginBottom: '1rem',
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  
  return (
    <><Helmet>
      <title>About Subtitles.center</title>
    </Helmet>
    <div style={styles.page}>
        <InnerPagesHeader />
        <main style={styles.content}>
          <div style={styles.mainTitle}>
            <h1 style={styles.title}>About Subtitles center</h1>
          </div>

          <section style={styles.section}>
            <h2 style={styles.sectionTitle}>Our Story</h2>
            <p style={styles.paragraph}>
              Subtitles.center emerged from a vision to bridge language barriers in the digital age. Founded in the early 2020s, our company began as a small team of language enthusiasts and tech innovators. We recognized the growing need for efficient and accurate subtitle solutions in an increasingly globalized media landscape.
            </p>
            <p style={styles.paragraph}>
              As a subsidiary of a larger, well-established technology corporation, we've been able to leverage cutting-edge resources and expertise to develop our AI-powered subtitle generation platform. Our parent company's commitment to innovation has allowed us to push the boundaries of what's possible in language technology.
            </p>
          </section>

          <section style={styles.section}>
            <h2 style={styles.sectionTitle}>Our Mission</h2>
            <p style={styles.paragraph}>
              At Subtitles.center, we're dedicated to making content accessible to audiences worldwide. Our mission is to provide fast, accurate, and culturally sensitive subtitle solutions that empower content creators and help businesses reach global markets.
            </p>
          </section>

          <section style={styles.section}>
            <h2 style={styles.sectionTitle}>Our Technology</h2>
            <p style={styles.paragraph}>
              Our proprietary AI-driven subtitle generation system is the result of years of research and development. By combining machine learning algorithms with linguistic expertise, we've created a platform that can produce high-quality subtitles in multiple languages with unprecedented speed and accuracy.
            </p>
          </section>

          <section style={styles.section}>
            <h2 style={styles.sectionTitle}>Looking Forward</h2>
            <p style={styles.paragraph}>
              As we continue to grow and evolve, we remain committed to our core values of innovation, accuracy, and accessibility. We're excited about the future of language technology and the role we'll play in shaping it. With the support of our parent company and the dedication of our talented team, we're poised to continue leading the way in subtitle solutions for years to come.
            </p>
          </section>
        </main>
        <Footer />
      </div></>
  );
}

export default About;
