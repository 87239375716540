import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    body {
       margin:0;
       padding:0;

    }

    *, *::before, *::after {
        box-sizing: border-box;
    }
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #999;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #ccc;
    }

    
`;
