import React, {useEffect} from 'react';
import Footer from './Footer'
import InnerPagesHeader from './InnerPagesHeader'
import { Helmet } from "react-helmet";

function Safety() {

  const colors = {
    primaryBlue: '#3498db',
    darkGrey: '#34495e',
    white: '#ffffff',
  };

  const styles = {
    page: {
      fontFamily: 'Arial, sans-serif',
      color: colors.darkGrey,
      margin: 0,
      padding: 0,
      backgroundColor: colors.white,
    },
    mainTitle: {
      color: 'rgb(26,26,26)',
      padding: '2rem',
      textAlign: 'center',
    },
    title: {
      fontSize: '2.5rem',
      marginBottom: '1rem',
    },
    content: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '2rem',
    },
    section: {
      marginBottom: '2rem',
    },
    sectionTitle: {
      fontSize: '1.8rem',
      color:  'rgb(26,26,26)',
      marginBottom: '1rem',
    },
    paragraph: {
      lineHeight: '1.6',
      marginBottom: '1rem',
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  
  return (
    <><Helmet>
      <title>Subtitles.center safety</title>
    </Helmet>
    <div style={styles.page}>
        <InnerPagesHeader />
        <main style={styles.content}>
          <div style={styles.mainTitle}>
            <h1 style={styles.title}>Privacy and Safety</h1>
          </div>

          <section style={styles.section}>
            <h2>Our Commitment to Your Privacy</h2>
            <p style={styles.paragraph}>
              At Subtitles.center, we understand the sensitive nature of private video content and the subtitles associated with them. Our advanced systems are designed with privacy at their core, ensuring that your data remains protected throughout the subtitling process.
            </p>
            <p style={styles.paragraph}>
              We employ industry-standard encryption protocols and secure data handling practices to safeguard your information. Our team is committed to maintaining the confidentiality of all content processed through our platform.
            </p>
          </section>

          <section style={styles.section}>
            <h2>Secure Subtitle Generation</h2>
            <p style={styles.paragraph}>
              Our proprietary subtitle generation technology operates within a secure environment. We've implemented multiple layers of protection to ensure that your video content and generated subtitles are accessible only to authorized personnel.
            </p>
            <p style={styles.paragraph}>
              While we can't disclose the specifics of our security measures, rest assured that we continuously update and improve our systems to stay ahead of potential threats.
            </p>
          </section>

          <section style={styles.section}>
            <h2>Data Retention and Deletion</h2>
            <p style={styles.paragraph}>
              We have established robust data retention policies that balance the need for service improvement with respect for your privacy. Our systems are designed to automatically remove sensitive information after it has served its purpose, in accordance with applicable regulations and best practices.
            </p>
          </section>

          <section style={styles.section}>
            <h2>Compliance with Global Standards</h2>
            <p style={styles.paragraph}>
              Subtitles.center adheres to various international privacy standards and regulations. We regularly review and update our practices to ensure compliance with evolving global privacy laws.
            </p>
          </section>

          <section style={styles.section}>
            <h2>Empowering User Control</h2>
            <p style={styles.paragraph}>
              We believe in giving our users control over their data. Our platform includes features that allow you to manage your content and subtitle data, though specific capabilities may vary based on the nature of the project and contractual agreements.
            </p>
          </section>

          <section style={styles.section}>
            <h2>Continuous Improvement</h2>
            <p style={styles.paragraph}>
              The landscape of digital privacy and security is ever-changing, and so are our practices. We're committed to continuously enhancing our privacy and safety measures to provide you with the most secure subtitling experience possible.
            </p>
          </section>

          <section style={styles.section}>
            <h2>Your Trust, Our Priority</h2>
            <p style={styles.paragraph}>
              At Subtitles.center, we view your trust as our most valuable asset. While we can't divulge all the details of our security infrastructure, we want you to feel confident that when you choose our services, you're choosing a partner that prioritizes the privacy and safety of your content.
            </p>
            <p style={styles.paragraph}>
              For more information about our privacy practices or to address any concerns, please don't hesitate to contact our dedicated privacy team.
            </p>
          </section>
        </main>
        <Footer />
      </div></>
  );
}

export default Safety;
