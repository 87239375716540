import React, {useEffect} from 'react';
import Footer from './Footer'
import InnerPagesHeader from './InnerPagesHeader'
import { Helmet } from "react-helmet";

function InternationalLaws() {

  const colors = {
    primaryBlue: '#3498db',
    darkGrey: '#34495e',
    white: '#ffffff',
  };

  const styles = {
    page: {
      fontFamily: 'Arial, sans-serif',
      color: colors.darkGrey,
      margin: 0,
      padding: 0,
      backgroundColor: colors.white,
    },
    mainTitle: {
      color: 'rgb(26,26,26)',
      padding: '2rem',
      textAlign: 'center',
    },
    title: {
      fontSize: '2.5rem',
      marginBottom: '1rem',
    },
    content: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '2rem',
    },
    section: {
      marginBottom: '2rem',
    },
    sectionTitle: {
      fontSize: '1.8rem',
      color:  'rgb(26,26,26)',
      marginBottom: '1rem',
    },
    paragraph: {
      lineHeight: '1.6',
      marginBottom: '1rem',
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <><Helmet>
      <title>Subtitles Laws - Subtitles.center</title>
    </Helmet>
    <div style={styles.page}>
        <InnerPagesHeader />
        <main style={styles.content}>
          <div style={styles.mainTitle}>
            <h1 style={styles.title}>International Laws on Video Subtitles</h1>
          </div>

          <section style={styles.section}>
            <h2 style={styles.sectionTitle}>Accessibility Laws</h2>
            <p style={styles.paragraph}>
              Many countries have enacted laws requiring subtitles or closed captions for accessibility purposes. These laws aim to ensure that audiovisual content is accessible to deaf and hard-of-hearing individuals, as well as those learning the language or watching in sound-sensitive environments.
            </p>
            <h3>United States</h3>
            <ul style={styles.list}>
              <li style={styles.listItem}>
                <strong>Americans with Disabilities Act (ADA):</strong> Requires that places of public accommodation, including websites and online video platforms, be accessible to individuals with disabilities.
              </li>
              <li style={styles.listItem}>
                <strong>21st Century Communications and Video Accessibility Act (CVAA):</strong> Mandates captioning for television programs redistributed on the internet and requires video programming distributors to pass through closed captions.
              </li>
              <li style={styles.listItem}>
                <strong>Section 508 of the Rehabilitation Act:</strong> Requires federal agencies to make their electronic and information technology accessible to people with disabilities.
              </li>
            </ul>
            <h3>European Union</h3>
            <ul style={styles.list}>
              <li style={styles.listItem}>
                <strong>European Accessibility Act:</strong> Sets accessibility requirements for products and services, including audiovisual media services.
              </li>
              <li style={styles.listItem}>
                <strong>Audiovisual Media Services Directive (AVMSD):</strong> Requires EU member states to encourage media service providers to ensure their services are gradually made accessible to people with visual or hearing disabilities.
              </li>
            </ul>
            <h3>United Kingdom</h3>
            <ul style={styles.list}>
              <li style={styles.listItem}>
                <strong>Equality Act 2010:</strong> Requires service providers to make reasonable adjustments to ensure their services are accessible to disabled people, which can include providing subtitles for video content.
              </li>
              <li style={styles.listItem}>
                <strong>Communications Act 2003:</strong> Sets targets for subtitling, sign language, and audio description on television.
              </li>
            </ul>
            <h3>Canada</h3>
            <ul style={styles.list}>
              <li style={styles.listItem}>
                <strong>Accessible Canada Act:</strong> Aims to create a barrier-free Canada by 2040, which includes making digital content accessible.
              </li>
              <li style={styles.listItem}>
                <strong>Canadian Radio-television and Telecommunications Commission (CRTC) regulations:</strong> Require most broadcasters to caption 100% of their programs during the broadcast day.
              </li>
            </ul>
            <p style={styles.paragraph}>
              These laws and regulations demonstrate a global trend towards ensuring digital content accessibility. Content creators and distributors must be aware of and comply with these requirements in the jurisdictions where they operate or distribute content.
            </p>
          </section>
        </main>
        <Footer />
      </div></>
  );
}

export default InternationalLaws;