import React, {useEffect} from 'react';
import Footer from '../Footer'
import InnerPagesHeader from '../InnerPagesHeader'
import { Helmet } from "react-helmet";

function SubtitleEditor() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const colors = {
    primaryBlue: '#3498db',
    darkGrey: '#34495e',
    white: '#ffffff',
  };

  const styles = {
    page: {
      fontFamily: 'Arial, sans-serif',
      color: colors.darkGrey,
      margin: 0,
      padding: 0,
      backgroundColor: colors.white,
    },
    mainTitle: {
      color: 'rgb(26,26,26)',
      padding: '2rem',
      textAlign: 'center',
    },
    title: {
      fontSize: '2.5rem',
      marginBottom: '1rem',
    },
    content: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '2rem',
    },
    section: {
      marginBottom: '2rem',
    },
    sectionTitle: {
      fontSize: '1.8rem',
      color:  'rgb(26,26,26)',
      marginBottom: '1rem',
    },
    paragraph: {
      lineHeight: '1.6',
      marginBottom: '1rem',
    },
  };

  return (
    <><Helmet>
      <title>Subtitles Editor job - Subtitles.center</title>
    </Helmet>
    <div style={styles.page}>
        <InnerPagesHeader />
        <main style={styles.content}>
          <div style={styles.mainTitle}>
            <h1 style={styles.title}>Subtitle Editor: Qualifications and Responsibilities</h1>
          </div>

          <section style={styles.section}>
            <h2 style={styles.sectionTitle}>Qualifications</h2>
            <ul style={styles.list}>
              <li style={styles.listItem}>Native or near-native proficiency in the target language</li>
              <li style={styles.listItem}>Excellent command of the source language (usually English)</li>
              <li style={styles.listItem}>Strong writing and proofreading skills</li>
              <li style={styles.listItem}>Familiarity with subtitling software and techniques</li>
              <li style={styles.listItem}>Understanding of timing and synchronization in subtitling</li>
              <li style={styles.listItem}>Knowledge of cultural nuances and idiomatic expressions</li>
            </ul>
          </section>

          <section style={styles.section}>
            <h2 style={styles.sectionTitle}>Job Requirements</h2>
            <ul style={styles.list}>
              <li style={styles.listItem}>Ability to work with various video formats and resolutions</li>
              <li style={styles.listItem}>Familiarity with subtitle file formats (SRT, VTT, ASS, etc.)</li>
              <li style={styles.listItem}>Understanding of reading speed and character limits for subtitles</li>
              <li style={styles.listItem}>Ability to meet tight deadlines and handle multiple projects</li>
              <li style={styles.listItem}>Excellent time management and organizational skills</li>
              <li style={styles.listItem}>Attention to detail and commitment to quality</li>
            </ul>
          </section>

          <section style={styles.section}>
            <h2 style={styles.sectionTitle}>Responsibilities</h2>
            <ul style={styles.list}>
              <li style={styles.listItem}>Edit and proofread machine-generated or human-translated subtitles</li>
              <li style={styles.listItem}>Ensure proper timing and synchronization of subtitles with audio and video</li>
              <li style={styles.listItem}>Adapt dialogue to fit character limits and reading speed requirements</li>
              <li style={styles.listItem}>Maintain consistency in terminology and style throughout projects</li>
              <li style={styles.listItem}>Research industry-specific terminology and cultural references</li>
              <li style={styles.listItem}>Collaborate with translators, project managers, and quality assurance teams</li>
              <li style={styles.listItem}>Stay updated on industry standards and best practices in subtitling</li>
              <li style={styles.listItem}>Provide feedback on subtitle quality and suggest improvements to workflows</li>
            </ul>
          </section>

          <section style={styles.section}>
            <h2 style={styles.sectionTitle}>Additional Skills</h2>
            <ul style={styles.list}>
              <li style={styles.listItem}>Familiarity with audio description for visually impaired audiences</li>
              <li style={styles.listItem}>Knowledge of closed captioning for deaf and hard-of-hearing viewers</li>
              <li style={styles.listItem}>Understanding of SEO principles for subtitle optimization</li>
              <li style={styles.listItem}>Basic video editing skills</li>
              <li style={styles.listItem}>Familiarity with machine translation and post-editing processes</li>
            </ul>
          </section>
        </main>
        <Footer />
      </div></>
  );
}

export default SubtitleEditor;
