import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router,  Routes,  Route} from "react-router-dom"
import GlobalStyle from './GlobalStyle';
import InternationalLaws from './InternationalLaws';
import Home from './Home';
import About from './About'
import SubtitleEditor from './earn/SubtitleEditor'
import Franchise from './earn/Franchise'
import Affiliate from './earn/Affiliate'
import Safety from './Safety';
import Careers from './Careers';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <Router>  
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/subtitles-international-laws" element={<InternationalLaws/>} />
          <Route path="/about-subtitles-center" element={<About/>} />
          <Route path="/earn/subtitles-center-earn-by-editing-subtitles" element={<SubtitleEditor/>} />
          <Route path="/earn/subtitles-center-franchise" element={<Franchise/>} />
          <Route path="/earn/subtitles-center-affiliate" element={<Affiliate/>} />
          <Route path="/subtitles-center-privacy-safety" element={<Safety/>} />
          <Route path="/subtitles-center-careers" element={<Careers/>} />
          
        </Routes>
    </Router> 
  </React.StrictMode>
);



